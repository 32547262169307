import React from "react"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const RentwareArticleBooking = ({ articleId, view}) => {
    return (
        <div 
        className={classNames(
        view === "calendar" ? 'min-h-[600px]' : 'min-h-[280px]',
        "mx-auto min-w-[320px]"
        )}>
            <rtr-article-booking article-id={articleId} view={view ?? 'default'} show-fully-booked-days="on"></rtr-article-booking>
        </div>
    )
}

export default RentwareArticleBooking