import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
// import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ seo = {} }) => {
  // const { strapiGlobal } = useStaticQuery(query);
  // const { defaultSeo, siteName, favicon } = strapiGlobal;

  // // Merge default and page-specific Seo values
  // const fullSeo = { ...defaultSeo, ...seo };

  // const getMetaTags = () => {
  //   const tags = [];

  //   if (fullSeo.metaTitle) {
  //     tags.push(
  //       {
  //         property: "og:title",
  //         content: fullSeo.metaTitle,
  //       },
  //       {
  //         name: "twitter:title",
  //         content: fullSeo.metaTitle,
  //       }
  //     );
  //   }
  //   if (fullSeo.metaDescription) {
  //     tags.push(
  //       {
  //         name: "description",
  //         content: fullSeo.metaDescription,
  //       },
  //       {
  //         property: "og:description",
  //         content: fullSeo.metaDescription,
  //       },
  //       {
  //         name: "twitter:description",
  //         content: fullSeo.metaDescription,
  //       }
  //     );
  //   }
  //   // if (fullSeo.shareImage) {
  //   //   const imageUrl =
  //   //     (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
  //   //     fullSeo.shareImage.url;
  //   //   tags.push(
  //   //     {
  //   //       name: "image",
  //   //       content: imageUrl,
  //   //     },
  //   //     {
  //   //       property: "og:image",
  //   //       content: imageUrl,
  //   //     },
  //   //     {
  //   //       name: "twitter:image",
  //   //       content: imageUrl,
  //   //     }
  //   //   );
  //   // }
  //   if (fullSeo.article) {
  //     tags.push({
  //       property: "og:type",
  //       content: "article",
  //     });
  //   }
  //   tags.push({ name: "twitter:card", content: "summary_large_image" });

  //   return tags;
  // };

  // const metaTags = getMetaTags();

  return (
    <Helmet
      // title={fullSeo.metaTitle}
      titleTemplate={`%s | ${Seoquery.siteName}`}
      link={[
        {
          rel: "icon",
          href: Seoquery.favicon.url,
        },
      ]}
      metaTitle={Seoquery.metaTitle}
    />
  );
};

export default Seo;

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

// const query = graphql`
//   query {
//     strapiGlobal {
//       siteName
//       favicon {
//         url
//       }
//       defaultSeo {
//         metaTitle
//         metaDescription
//         # shareImage {
//         #   url
//         # }
//       }
//     }
//   }
// `;
const Seoquery = {
  siteName: 'Rentware LP',
  favicon: {
    url: 'favicon.ico',
  },
  defaultSeo: {
    metaTitle: 'metaTitle',
    metaDescription: 'metaDescription',
  }
}
